.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #F5A9C5;
  min-height: 100vh;

  font-size: calc(10px + 2vmin);
  color: white;
}

@media (max-width: 640px) {
  .flex-sm-col {
    flex-direction: column !important;
  }
}



